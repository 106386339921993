canvas {
  width: 100vw;
  height: 100vh;
}
#intro {
    // background-color: #1f3d3d;
    background: rgb(0,0,0);
    background: radial-gradient(circle, rgba(0,0,0,1) 0%, rgba(31,61,61,1) 0%, rgba(0,0,0,1) 100%);

    color: white;
    height: calc(100vh + 53px);
    display: flex;
    align-items: center;
    justify-content: center;

    canvas {
      position: absolute;
      width: 100%;
      height: 100%;
    }
    
    .center {
      padding: 40px;
      margin: auto;
      font-family: Raleway;
      text-shadow: 1px 1px 1px black;
      font-weight: 500;
      // background-image: url("../../public/coffee-stain.png");
      background-size: 300px;
      background-position: top right;
      background-repeat: no-repeat;
      // mix-blend-mode: color-dodge;

      font-size: calc(18px + 2vw);
      
      @media screen and (min-width: 768px) {
        font-size: 44px;
        padding: 80px;
        margin: -80px;
        display: block;
        max-width: 600px;
        max-height: 200px;
      }
    }
    
    button.viewMore {
      position: relative;
      color: white;
      font-display: block; 
      font-family: Raleway;
      font-size: 24px;
      margin-top: 24px;
      background-color: transparent;
      padding: 0;
      border: solid 4px white;
      cursor: pointer;
      transition-delay: 0.3s;
      transition: background-color 0.2s linear;
      transition: border-color 0.2s linear;
      
      a {
        display: block;
        text-decoration: none;
        color: white;
        padding: 12px 48px 12px 12px;
      }
  
      &:hover {
        background-color: brown;
        border-color: brown;
        .arrow {
          transform: rotate(90deg);
        }
      }
      
      .arrow {
        transition-delay: 0.3s;
        transition: transform 0.2s;
        position: absolute;
        top: 25px;
        width: 20px;
        height: 3px;
        margin-left: 12px;
        background-color: #fff;
      }
      
      .arrow::after, .arrow::before {
        content: '';
        position: absolute;
        width: 16px;
        height: 3px;
        right: -4px;
        background-color: #fff;
      }
      
      .arrow::after {
        top: -5px;
        transform: rotate(45deg);
      }
      
      .arrow::before {
        top: 5px;
        transform: rotate(-45deg);
      }
    }
  }