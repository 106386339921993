@import url("https://fonts.googleapis.com/css2?family=Raleway:wght@300;500&family=Roboto+Slab&display=swap");

@font-face {
  font-family: "Roboto Slab";
  src: url("https://fonts.googleapis.com/css2?family=Raleway:wght@300;500&family=Roboto+Slab&display=swap") format("truetype");
  font-weight: normal;
  font-style: normal;
}

html {
  scroll-behavior: smooth;
  overflow-x: hidden;
  &.modal-open {
    overflow: hidden;
  }
}
#root {
  margin: 0;
  padding: 0;
}
h1 {
  font-family: Roboto Slab;
  width: 100%;
  font-size: 18px;
  text-transform: uppercase;
  text-align: left;
  letter-spacing: 6px;
  font-weight: 500;
  color: #555;
  display: inline-block;
  margin: 0;
  padding: 40px 0 20px 20px;
  
  @media screen and (min-width: 1024px) {
    text-align: center;
  }
}

button.download {
  // background-color: #1f3d3d;
  position: absolute;
  right: 0;
  top: 20px;
  background-color: transparent;
  background-image: url("../../../public/download-icon.svg");
  background-size: 32px;
  background-position: center;
  background-repeat: no-repeat;
  border: none;
  width: 64px;
  height: 64px;
  padding: 10px;
  cursor: pointer;

  &:hover {
    background-color: brown;
    filter: invert(100%);
  }
}
a.external {
  font-size: 14px;
  color: #333;
  padding: 0 16px 0 0;
}
a.external {
  background-image: url("../../../public/external_link_icon.png");
  background-size: 14px;
  background-position: right;
  background-repeat: no-repeat;
}

section {
  margin: 0 auto;
}

p {
  font-size: 16px;
  font-weight: 100;
  text-align: left;
}
.sectionTitle {
  width: 100%;
  top: 55px;
  position: sticky;
  margin-right: 0%;
  text-align: left;
  @media screen and (min-width: 1024px) {
    text-align: center;
  }
  background-color: rgb(255,255,255, 0.8);
}

.slide-right
{
  animation: 1s slide-right forwards;
  transform:translateX(-100%);
}

.underline {
  // font-family: "";
  background: #555;
  margin: 22px auto 45px;
  width: 50px;
  height: 2px;
  
  @media screen and (min-width: 1024px) {
    height: 4px;
    margin: 25px auto 75px;
    width: 40px;
  }
}

#job-list {
  padding: 0 18px;
  @media screen and (min-width: 1024px) {
    padding: 0 320px 0;
  }
}

#curriculum-vitae {
  position: relative;
  max-width: 1220px;
  &:before {
    content: "";
    display: block;
    height: 95px;
    margin-top: -95px;
    @media screen and (min-width: 1024px) {
      height: 55px;
      margin-top: -55px;
    }
    visibility: hidden;
  }
}
#key-skills {
  background-color: brown;
}
#about-me {
  position: relative;
  margin-bottom: 80px;
}

#profileImage {
  position: absolute;
  max-width: 100px;
  margin-top: 24px;
  margin-left: -134px;
}

#container {
  width: 100%;
  overflow: hidden;
  // max-width: 768px;
  margin: 0 auto;
  // padding: 20px 0 0 0;
  padding: 0;
}

@keyframes slide-right {
  to {
    transform:translateX(0);
  }
}