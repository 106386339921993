header {
    font-family: Raleway;
    width: 100%;
    background-color: #1d1d1d;
    position: fixed;
    color: white;
    z-index: 100;
    box-shadow: inset 0 0 0 0 black;
    transition: ease-out 0.4s;
    animation-delay: 250ms;

    .bar {
        height: 46px;
        position: absolute;
        background-color: #000;
        width: 100%;
        // height: 100%;
        z-index: -10;
        @media screen and (min-width: 1024px) {
            height: 100%;
            animation: slideRight 0.6s ease-out, blink 1s step-end infinite;
            width: 50%;
        }
    }
    
    .wrapper {
        width: 100%;
        max-width: 500px;
        margin: 0 auto;
    }
    
    #links {
        position: absolute;
        right: 16px;
        top: 58px;

        @media screen and (min-width: 1024px) {
            position: relative;
            top: auto;
            right: auto;
            padding: 12px 80px 0;
        }

        img {
            filter: grayscale(100%);
            &:hover {
                transition: ease-out 0.4s;
                filter: grayscale(0%);
            }
        }
    }

    .center {
        @media screen and (min-width: 1024px) {
            display: flex;
            max-width: 600px;
            margin: 0 auto;
        }
    }

    .title {
        display: flex;
        font-family: monospace;
        font-size: 18px;
        font-weight: 400;
        padding: 12px 0;
        letter-spacing: 2px;
        justify-content: left;

        // text-align: right;
        // height: 100px;
        @media screen and (min-width: 1024px) {
            align-items: center;
            min-width: 300px;
            height: auto;
        }

        .typer {
            width: 230px;
            overflow: hidden; /* Ensures the content is not revealed until the animation */
            white-space: nowrap; /* Keeps the content on a single line */
            margin: 0 auto; /* Gives that scrolling effect as the typing happens */
            border-right: 0.15em solid white; /* The typwriter cursor */
            letter-spacing: 0.15em; /* Adjust as needed */
            animation: typing 1.5s steps(16, end), blink 0.75s step-end infinite;
        }

        @media screen and (min-width: 1024px) {
            // margin: 0 120px 0 0;
            // padding: 12px 20px 12px 162px;
        }
    }
    nav {
        // position: absolute;
        // right: 60px;
        @media screen and (min-width: 1024px) {
            position: relative;
            margin-left: 80px;
            min-width: 300px;
        }
        // display: inline-block;
        // justify-content: space-between;
    }
    
    ul {
        // display: flex;
        // justify-content: center;
        @media screen and (min-width: 1024px) {
            display: inline-block;
        }
        margin: 0;
        padding: 0;
        li {
            text-transform: uppercase;
            align-items: center;
            list-style-type: none;
            display: inline;
            padding: 0;

            a {
                max-width: 120px;
                display: inline-block;
                font-weight: 100;
                padding: 18px 28px;
                text-decoration: none;
                color: white;
                box-shadow: inset 0 0 0 0 brown;
                transition: ease-out 0.4s;
                animation-delay: 250ms;

                &:hover,
                &.selected {
                    box-shadow: inset 400px 0 0 0 brown;
                }
            }
        }
    }
}

@keyframes slideRight {
    0% {
        width: 0%;
    }
    100% {
        width: 50%;
    }
}
@keyframes blink {
    from,
    to {
        border-color: transparent;
    }
    50% {
        border-color: white;
    }
}

@keyframes typing {
    from {
        width: 0;
    }
    to {
        width: 230px;
    }
}
