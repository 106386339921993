.jobListItem
{
    font-size: 16px;
    font-weight: 100;
    text-align: left;
    margin-bottom: 22px;
    padding-bottom: 12px;
    border-bottom: 1px solid #ccc;

    .skillHighlight
    {
        padding: 3px;
        font-weight: 600;
        margin: 0;
        background-color: yellowgreen;
    }

    &_info {
        font-size: 14px;
        color: #111;
        display: flex;
        &Text {
            ul {
                padding: 0;
                margin: 0;
                li {
                    list-style-type: none;
                    &:first-of-type {
                        font-size: 18px;
                        font-weight: 600;
                        color: #2d2d2d;
                    }
                    &:nth-of-type(2) {
                        font-size: 16px;
                        color: #2d2d2d;
                    }
                }
            }
        }
    }
    h2 {
        font-size: 16px;
        font-weight: 600;
        color: #666;
    }
    &_companyLogo {
        margin-right: 22px;
    }
    &_dates {
        position: absolute;
        color: #3c4646;
        margin-left: -120px;
        padding: 0;

        p {
            margin: 0;
        }
    }
}